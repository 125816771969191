export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'en',
  messages: {
    en: {
      column_headers: {
        asset: {
          bank: {
            name: 'Account name',
            institution: 'Institution',
            value: 'Current Value',
          },
          creditCard: {
            name: 'Account name',
            institution: 'Institution',
            value: 'Current Value',
          },
          investment: {
            name: 'Account name',
            institution: 'Institution',
            value: 'Current Value',
          },
          loan: {
            name: 'Account name',
            institution: 'Institution',
            value: 'Current Value',
          },
          retirement: {
            name: 'Account name',
            institution: 'Institution',
            value: 'Current Value',
          },
          vehicle: {
            name: 'Account name',
            institution: 'Vehicle type',
            value: 'Current Value',
          },
          realEstate: {
            name: 'Account name',
            institution: 'Institution',
            value: 'Current Value',
          },
          business: {
            name: 'Account name',
            institution: 'Business type',
            value: 'Current Value',
          },
          other: {
            name: 'Account name',
            institution: 'Asset type',
            value: 'Current Value',
          },
        },
      },
      marital_property: {
        earned_income: 'Earned Income',
        passive_income: 'Passive Income',
        investment_accounts: 'Investment Accounts',
        retirement_accounts: 'Retirement Accounts',
        vehicles: 'Vehicles',
        real_estate: 'Real Estate',
        business_interests: 'Business Interests',
        loans_and_debt: 'Loans and Debt',
      },
      section_status: {
        not_started: 'Not Started',
        next: 'Up next',
        active: 'Get started',
        started: 'Continue',
        completed: 'Completed',
        review: 'Review',
        update_review: 'Review',
        waiting: 'Waiting',
      },
      section_status_partner: {
        not_started: 'Not Started',
        next: 'Not Started',
        active: 'Not Started',
        started: 'In progress',
        completed: 'Completed',
        review: 'In progress',
        update_review: 'In progress',
        waiting: 'Waiting',
      },
      allocation: {
        true: 'Keep seperate',
        false: 'Make community',
      },
      agree: {
        true: 'Agree',
        false: 'Disagree',
        null: '',
      },
      our_prenup_header: {
        drafting: {
          card: 'Your prenup will be available to sign in {daysLeft} days.',
          header: 'California has a 7-day rule for prenups.',
          description:
            'This is a rule that ensures both partners have seven calendar days to contemplate the agreement. Calendar days include weekends and holidays. We encourage you to review all of your documents during this time period.',
          status: 'View',
        },
        signing: {
          card: ({ values }: { values: { partnerName: string; userSigned: boolean } }) => {
            return values.userSigned
              ? `Waiting for ${values.partnerName} to sign the prenup.`
              : 'Your prenup is available to sign now.'
          },
          header: ({ values }: { values: { partnerName: string; userSigned: boolean } }) => {
            return values.userSigned
              ? `Once ${values.partnerName} signs, it will be official.`
              : 'Your prenup is ready to be signed!'
          },
          description: ({ values }: { values: { partnerName: string; userSigned: boolean } }) => {
            return values.userSigned
              ? ''
              : `Once you and ${values.partnerName} have both signed the prenup, it will be official.`
          },
          status: ({ values }: { values: { partnerName: string; userSigned: boolean } }) => {
            return values.userSigned ? 'View' : 'Sign prenup'
          },
          statuss: 'Sign prenup',
        },
        finalized: {
          card: '',
          header: 'Signed. Sealed. Delivered. The prenup is yours.',
          description: 'You can come back here to view or download your documents at any time.',
          status: 'Download',
        },
      },
    },
  },
}))
